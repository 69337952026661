import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

class PageNotFound extends React.Component {
  render() {
    const pageTitle = "404 - PageNotFound"

    return (
      <Layout location={this.props.location}>
        <Helmet title={pageTitle} />
        <h1>404 - Page Not Found</h1>
      </Layout>
    )
  }
}

export default PageNotFound
